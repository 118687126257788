import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ResetPasswordForm from '../../components/ResetPasswordForm';
import resetPasswordForm from '../../forms/resetPasswordForm';
import userStore from '../../stores/userStore';

const ResetPasswordContainer = () => {
  const { clearResetPasswordState } = userStore;

  useEffect(() => {
    return function cleanup() {
      clearResetPasswordState();
    }
  })

  return (
    <div className="private-pages__wrapper private-pages__wrapper--center">
      <section className="section-reset-password">
        <h1 className="section-login__heading">Reset password</h1>
        <div className="section-reset-password__content">
          <ResetPasswordForm form={resetPasswordForm} />
        </div>
      </section>
    </div>
  )
}

export default observer(ResetPasswordContainer)