import styles from "./AccountMenu.module.scss";
import React from "react";
import { Link } from "react-router-dom";
import { HandySvg } from "handy-svg";

function AccountMenu({ tab }) {
	return (
		<div className={styles.menu}>
			<Link to="/" title="go to main page" className={styles.logo}>
				<HandySvg
					width={96}
					height={44}
					src={"/static/logo-vicuesoft-white-full.svg"}
					className="Header-vicuesoft-logo"
				/>
			</Link>

			<Link
				to="/profile?tab=general"
				className={`${styles.link} ${tab === "general" ? styles["link-active"] : styles["link-not_active"]}`}
			>
				<HandySvg width={40.86} height={40.86} src={"/static/profile_general.svg"} />
				<span>General</span>
			</Link>
			<Link
				to="/profile?tab=billing_and_payments"
				className={`${styles.link} ${
					tab === "billing_and_payments" ? styles["link-active"] : styles["link-not_active"]
				}`}
			>
				<HandySvg width={40.86} height={40.86} src={"/static/profile_billing_and_payments.svg"} />
				<span>Billing & Payments</span>
			</Link>
			<Link
				to="/profile?tab=settings"
				className={`${styles.link} ${tab === "settings" ? styles["link-active"] : styles["link-not_active"]}`}
			>
				<HandySvg width={37.51} height={40} src={"/static/profile_settings.svg"} />
				<span>Settings</span>
			</Link>
		</div>
	);
}

export default AccountMenu;
