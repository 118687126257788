import MobxReactForm from "mobx-react-form";
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import userStore from '../stores/userStore';

const plugins = {
  dvr: dvr(validatorjs)
}

const fields = [{
  name: 'email',
  label: 'Email',
  placeholder: '',
  rules: 'required|email|string',
  value: '',
  type: 'email'
}, {
  name: 'password',
  label: 'Password',
  placeholder: '',
  rules: 'required|string',
  type: 'password'
}]

const hooks = {
  async onSuccess(form) {
    let resp = await userStore.singIn(form.values());
    if (resp) form.reset()
  },
  onError(form) {}
}

export default new MobxReactForm({ fields }, { plugins, hooks });