import React from "react";
import CopyBtn from "./copyBtn";
import styles from "./ProductItem.module.scss";
import { toast } from "react-toastify";

function ItemInfo({ license, date }) {
	const { licenseKey, status, server, current_period_end } = license;

	const isActiveLicense = () => {
		return status === "active" || status === "trialing";
	};

	const isCancelsLicense = () => status === "canceled";

	return (
		<div className={styles.info}>
			{licenseKey.length > 0 && (
				<React.Fragment>
					<div className={styles["info-item"]}>
						<p className={styles["info-title"]}>License key</p>

						<div className={styles["info-value"]}>
							<CopyBtn text={licenseKey} onCopy={() => toast.success("Copied license key")} />
							<span title={licenseKey}>{licenseKey}</span>
						</div>
					</div>
					<div className={styles["info-item"]}>
						<p className={styles["info-title"]}>Valid through</p>
						<div className={styles["info-value"]}>
							<span title={date}>{date}</span>
						</div>
					</div>
				</React.Fragment>
			)}
			{server !== null && (isActiveLicense() || (isCancelsLicense() && new Date(current_period_end) >= new Date())) && (
				<React.Fragment>
					<div className={styles["info-item"]}>
						<p className={styles["info-title"]}>Port</p>

						<div className={styles["info-value"]}>
							<CopyBtn text={server?.host?.length > 0 ? "443" : "7000"} onCopy={() => toast.success("Copied port")} />
							<span title={server?.host?.length > 0 ? "443" : "7000"}>{server?.host?.length > 0 ? "443" : "7000"}</span>
						</div>
					</div>
					<div className={styles["info-item"]}>
						<p className={styles["info-title"]}>Host</p>

						<div className={styles["info-value"]}>
							<CopyBtn
								text={server?.status === "ready" ? server.host || server.ip_address : server.status}
								onCopy={() => toast.success("Copied license host")}
							/>
							<span title={server?.status === "ready" ? server.host || server.ip_address : server.status}>
								{server?.status === "ready" ? server.host || server.ip_address : server.status}
							</span>
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
}

export default ItemInfo;
