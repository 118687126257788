import React from 'react';
import Plans from '../../components/Plans';
import HomePageBanner from '../../components/HomePageBanner';
import FrequentlyAskedQuestions from '../../components/FrequentlyAskedQuestions';
import DoYouHaveAQuestionBanner from '../../components/DoYouHaveAQuestionBanner';

const HomeContainer = () => {
  return (
    <>
      <div className="ubercontainer">
        <HomePageBanner />
        <Plans />
        <FrequentlyAskedQuestions />
        <DoYouHaveAQuestionBanner />
      </div>

    </>
  )
}
export default HomeContainer
