import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import FreePlanForm from '../FreePlanForm';
import form from '../../forms/freePlanForm';
import { HandySvg } from 'handy-svg';

const FreePlan = ({ plan, user }) => {
  useEffect(() => {
    if (user) {
      form.update({
        "first_name": user.first_name,
        "last_name": user.last_name,
        "country": user.country,
        "email": user.email
      });
    } else {
      form.reset();
    }
  });

  return (
    <>
      <HandySvg
        width={45}
        height={45.5}
        src={"/static/vq-probe.svg"}
        className="EnterprisePlanForm__logo"
      />
      <h1 className="VQProbeBasicForm__text">{plan.product_name} <span className="bold">{plan.product_mode}</span></h1>
      <FreePlanForm form={form} plan={plan} />
    </>
  )
}

export default observer(FreePlan);
