import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import { eq, get } from 'lodash/fp';
import HttpStatus from 'http-status-codes';
import plansStore from '../../stores/plansStore';
import userStore from '../../stores/userStore';
import SignInModal from '../SignInModal';
import './index.css';
import { validateEmail } from '../../util';
import * as ga from '../../seo/ga'

const FreePlanForm = ({ form, plan }) => {
  const [isPaying, setPaying] = useState(false);
  const [isDownloaded, setDownloaded] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const { user, setToken, getUser } = userStore;
  const { payPlan } = plansStore;

  const handlePay = async (event) => {
    event.preventDefault();

    const values = form.values();

    if (!validateEmail(values.email)) {
      toast.error("Invalid e-mail address")
      return
    }

    setPaying(true);
    setDownloaded(false);
    setShowSignIn(false);

    try {
      const res = await payPlan(plan.id, {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        country: values.country
      });
      if (res.data.auth_token) {
        await setToken(res.data.auth_token);
      }
      await getUser();
      setPaying(false);
      ga.clickEvent(`vqprobe-free-download`)
      if (res.data.download_link) {
        window.location.href = '/profile?tab=general';
      }
    } catch (e) {
      setPaying(false);
      if (eq(HttpStatus.FORBIDDEN, get('response.status')(e))) {
        setShowSignIn(true);
      }
      if (eq(HttpStatus.BAD_REQUEST, get('response.status')(e))) {
        console.log(e);
        toast.error(get('response.data.message')(e));
      }
      if (eq(HttpStatus.CONFLICT, get('response.status')(e))) {
        console.log(e);
        window.location.href = '/profile?tab=general';
      }
    }
  }

  const payIsDisabled = () => {
    const values = form.values();
    return values.email.trim() === "" ||
      values.first_name.trim() === "" ||
      values.last_name.trim() === "" ||
      values.country.trim() === "" ||
      isPaying || isDownloaded;
  }

  return (
    <>
      {!user && showSignIn && <SignInModal />}
      <form>
        <div className="FreePlanForm">
          <div className="FreePlanForm__row">
            <label htmlFor={form.$('first_name').id} className="FreePlanForm__heading">{form.$('first_name').label}<span>*</span></label>
            <input className="StripeElement FreePlanForm__input" {...form.$('first_name').bind()} />
          </div>
          <div className="FreePlanForm__row">
            <label htmlFor={form.$('last_name').id} className="FreePlanForm__heading">{form.$('last_name').label}<span>*</span></label>
            <input className="StripeElement FreePlanForm__input" {...form.$('last_name').bind()} />
          </div>
          <div className="FreePlanForm__row">
            <label htmlFor={form.$('email').id} className="FreePlanForm__heading">{form.$('email').label}<span>*</span></label>
            <input className="StripeElement FreePlanForm__input" {...form.$('email').bind()} readOnly/>
          </div>
          <div className="FreePlanForm__row">
            <label htmlFor={form.$('country').id} className="FreePlanForm__heading">{form.$('country').label}<span>*</span></label>
            <input className="StripeElement FreePlanForm__input" {...form.$('country').bind()} />
          </div>

          <p className="CheckoutForm-payment-agreement-info">
            By clicking "Get free",
            I agree with the <a href="https://vicuesoft.com/privacy-policy">Privacy Policy</a> and <a href="https://vicuesoft.com/terms-of-use">Terms of use</a>.
            Also that the ViCueSoft can keep me informed by sending personalized emails about products and services.
            See our <a href="https://vicuesoft.com/privacy-policy">Privacy Policy</a> for details.
          </p>

          <button
            className="FreePlanForm__button"
            disabled={payIsDisabled()}
            onClick={handlePay} >
            {isPaying ? <ClipLoader size={18} color="#fff" /> : "Get free"}
          </button>

        </div>
      </form>
    </>
  )
}

export default observer(FreePlanForm);
