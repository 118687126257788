import { observable, action, decorate, flow } from 'mobx';
import api from '../api/http';

class LicensesStore {
  licenses = [];
  showPMModal = null;

  getLicenses = () => flow(function* (self) {
    const resp = yield api.getLicenses();
    self.licenses = resp.data;
  })(this)

  cancelLicense = (data) => flow(function* (self) {
    try {
      yield api.cancelLicense(data);
    } catch (e) {
      console.log(e);
    }
  })(this)

  getDownloadLink = (id) => {
    return api.getDownloadLink(id);
  }

  manualActivation = (reqXML) => {
    return api.manualActivation(reqXML);
  }

  genLicenseKey = (id) => flow(function* (self) {
    yield api.genLicenseKey(id);
    self.getLicenses();
  })(this)

  cancelSubscription = (id, arr) => flow(function* (self) {
    try {
      yield api.cancelSubscription(id, {message: arr});
      self.getLicenses();
    } catch (e) {
      console.log(e);
    }
  })(this)

  resumeSubscription = (id) => flow(function* (self) {
    try {
      yield api.resumeSubscription(id);
      self.getLicenses();
    } catch (e) {
      console.log(e);
    }
  })(this)

  changePaymentMethod = (id, data) => flow(function* (self) {
    yield api.changePaymentMethod(id, data);
  })(this)

  setPMLicense = (license) => {
    this.pmLicense = license;
  }

  getPaymentsData = (id) => {
    return api.getPaymentsData(id);
  }

  shareLicenseAdd = (data)  => flow(function* (self) {
    yield api.shareLicenseAdd(data);
    self.getLicenses();
  })(this)

  shareLicenseDel = (data)  => flow(function* (self) {
    yield api.shareLicenseDel(data);
    self.getLicenses();
  })(this)
}

decorate(LicensesStore, {
  licenses: observable,
  pmLicense: observable,
  getLicenses: action,
  cancelLicense: action,
  getDownloadLink: action,
  genLicenseKey: action,
  cancelSubscription: action,
  resumeSubscription: action,
  changePaymentMethod: action,
  setPMLicense: action,
  getPaymentsData: action,
  shareLicenseAdd: action,
  shareLicenseDel: action
});

export default new LicensesStore();