import React from 'react';
import { Link } from 'react-router-dom';

const ProfileConfirm = () => {
  return (
    <>
      <section className="section-thank-you">
        <div className="section-thank-you__content">
          <div className="section-thank-you__content--text">
            <p className="paragraph paragraph--center">Now you can sign in to the site using your email and password.</p>
            <p className="paragraph paragraph--center"><small>Note: The functionality is subject to change without prior notice.</small></p>
          </div>
          <Link to={`/sign-in`} className="signin__button">Sign in</Link>
        </div>
      </section>
    </>
  )
}

export default ProfileConfirm;