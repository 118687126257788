import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import form from '../../forms/checkoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PlanDetail = ({ plan, user }) => {
  useEffect(() => {
    if (user) {
      form.update({
        "first_name": user.first_name,
        "last_name": user.last_name,
        "country": user.country,
        "email": user.email
      });
  
      if (user.first_name !== "" && user.last_name !== "") {
        form.update({"cardholder": `${user.first_name} ${user.last_name}`.toUpperCase()});
      }
    } else {
      form.reset();
    }
  });

  return (
    <Elements stripe={stripePromise}>
      <div className="section-payment__container">
        <CheckoutForm isFree={plan.is_free} form={form} plan={plan} />
      </div>
    </Elements>
  )
}

export default observer(PlanDetail);
