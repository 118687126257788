import React from 'react';
import { observer } from 'mobx-react';
import { Link } from "react-router-dom";
import './index.css';
import * as ga from '../../seo/ga'
import userStore from '../../stores/userStore';

const PlanItem = ({ plan, setPlansIsOpen, plansIsOpen }) => {
  const { user } = userStore;


  const renderPrice = () => {
    if (plan.is_enterprise) {
      return "Contact us for pricing"
    }

    if (plan.is_free) {
      return "Free";
    }

    return `€${plan.price_usd}`
  }

  return (
    <div className={`PlanItem PlanItem-${plan.type}`}>
      <div className="PlanItem-heading" onClick={() => { setPlansIsOpen(!plansIsOpen) }}>
        <div id={plan.type}></div>
        <div id="text" className="PlanItem-name">{plan.product_name} {plan.product_mode}</div>
        <div className="PlanItem-price-container">
          <div className={`PlanItem-price-amount PlanItem-price-free-${plan.is_free} PlanItem-price-enterprise-${plan.is_enterprise}`}>
            {renderPrice()}
          </div>
          {!plan.is_enterprise && !plan.is_free &&
            <div className="PlanItem-time-text">
              per month
            </div>
          }
        </div>
        {!plan.is_free && !plan.is_enterprise &&
          <div className="PlanItem-price-for-the-year">
            €{plan.price_yearly_usd} per year
          </div>
        }
        <div className="PlanItem-arrow-icon-container">
          <div className="PlanItem-arrow-icon"></div>
        </div>
      </div>
      <div className="PlanItem-feature-container">
        {plan.features.map((feature) => <div key={feature} className="PlanItem-feature"><div className="PlanItem-feature-bullet" />{feature}</div>)}
      </div>
      {user && <Link onClick={() => ga.clickEvent(`${plan.id}`)} className="PlanItem-button" to={`/plans/${plan.id}`}>{plan.price_text}</Link>}
      {!user && <Link to="/sign-in" className="PlanItem-button">{plan.price_text}</Link>}
    </div>
  )
}

export default observer(PlanItem);
