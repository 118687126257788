import MobxReactForm from "mobx-react-form";
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

const plugins = {
  dvr: dvr(validatorjs)
}

const fields = [{
  name: 'key',
  label: 'Key',
  placeholder: 'AAAA-AAAA-AAAA-AAAA-AAAA',
  type: 'text'
}]

const hooks = {
  onSuccess(form) {},
  onError(form) {}
}

export default new MobxReactForm({ fields }, { plugins, hooks });