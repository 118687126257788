import MobxReactForm from "mobx-react-form";
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

const plugins = {
  dvr: dvr(validatorjs)
}

const fields = [{
  name: 'email',
  label: 'Email',
  placeholder: '',
  rules: 'required|email|string',
  value: '',
  type: 'email'
}, {
  name: 'cardholder',
  label: 'Cardholder',
  placeholder: 'JENNY ROSEN',
  rules: 'required|string'
}, {
  name: 'coupon',
  label: 'Coupon',
  placeholder: 'A1B2C3D4',
  rules: 'string'
}, {
  name: 'first_name',
  label: 'First name',
  placeholder: '',
  rules: 'required|string',
  value: ''
}, {
  name: 'last_name',
  label: 'Last name',
  placeholder: '',
  rules: 'required|string',
  value: ''
}, {
  name: 'country',
  label: 'Country',
  placeholder: '',
  rules: 'required|string',
  value: ''
}, {
  name: 'seats',
  label: 'Seats',
  placeholder: '',
  rules: 'required|integer',
  value: 1
}, {
  name: 'commitment',
  label: 'Commitment',
  placeholder: '',
  rules: 'required',
  value: 'monthly'
}]

const hooks = {
  onSuccess(form) {},
  onError(form) {}
}

export default new MobxReactForm({ fields }, { plugins, hooks });