import React, {useState} from 'react';
import { observer } from 'mobx-react';
import plansStore from '../../stores/plansStore';
import PlanItem from '../PlanItem';
import './index.css';

const Plans = () => {
  const [plansIsOpenOne, setPlansIsOpenOne] = useState(false);
  const [plansIsOpenTwo, setPlansIsOpenTwo] = useState(false);


  const { plans } = plansStore;
  const vqaPlans = plans.filter((p) => p.type.toLowerCase() !== "vqprobe");
  const vqpPlans = plans.filter((p) => p.type.toLowerCase() === "vqprobe");

  return (
    <>
      <section className={`Plans ${plansIsOpenOne? 'active': ''}`}>
        {vqpPlans &&
          <>
            
            <h2 className="Plans-heading"><span className="Plans-image-vqp"></span>VQ Probe plans</h2>
            <div className="Plans-content">
              { vqpPlans.map((plan) => <PlanItem key={plan.id} plan={plan} setPlansIsOpen={setPlansIsOpenOne} plansIsOpen={plansIsOpenOne}/>) }
            </div>
          </>
        }
      </section>
      <section className={`Plans ${plansIsOpenTwo? 'active': ''}`}>
        {vqaPlans &&
          <>
            
            <h2 className="Plans-heading"><span className="Plans-image"></span>VQ Analyzer plans</h2>
            <div className="Plans-content">
              { vqaPlans.map((plan) => <PlanItem key={plan.id} plan={plan} setPlansIsOpen={setPlansIsOpenTwo} plansIsOpen={plansIsOpenTwo}/>) }
            </div>
          </>
        }
      </section>
    </>
  )
}

export default observer(Plans);
