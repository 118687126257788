import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { eq, get } from 'lodash/fp';
import HttpStatus from 'http-status-codes';
import ClipLoader from "react-spinners/ClipLoader";
import Modal from 'react-modal';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import api from '../../api/http';
import licensesStore from '../../stores/licensesStore';

const modelStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    backgroundColor       : '#e3f2ff',
  }
};

Modal.setAppElement('#root');

const ChangePaymentMethodModal = ({ license, form }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [modalIsOpen, setIsOpen] = useState(true);
  const [isChanging, setIsChanging] = useState(false);
  const { setPMLicense } = licensesStore;

  useEffect(() => {
    return function cleanup() {}
  })

  const closeModal = () => {
    setIsOpen(false);
    setPMLicense(null);
  }

  const handleChange = async (event) => {
    event.preventDefault();

    const values = form.values();

    setIsChanging(true);
    try {
      const card = elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(card, {name: values.cardholder});
      if (error) {
        toast.error(error.message);
        setIsChanging(false);
        return;
      }

      await api.changePaymentMethod(license.id, { "card_token": token.id });

      toast.success("Payment method changed successfully");
      setIsChanging(false);
      closeModal();
    } catch (e) {
      setIsChanging(false);
      if (eq(HttpStatus.BAD_REQUEST, get('response.status')(e))) {
        console.log(e);
        toast.error(get('response.data.message')(e));
      }
      if (eq(HttpStatus.INTERNAL_SERVER_ERROR, get('response.status')(e))) {
        toast.error("Failed to update payment method, please contact us at customersupport@vicuesoft.com")
      }
    }

    form.clear();
  }

  const handleCancel = () => {
    closeModal();
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modelStyles}>
        <div className="form-cpm">
          <div className="form-row">
            <label className="label">Card info</label>
            <CardElement />
          </div>

          <div className="form-row">
            <label htmlFor="name" className="label">Cardholder</label>
            <input className="StripeElement Name" {...form.$('cardholder').bind()}/>
          </div>

          <div className="form-row form-row__center">
            <button 
              className="section-login__form--button"
              onClick={handleCancel}>Cancel</button>
            <button 
              className="section-login__form--button"
              disabled={!stripe || isChanging} 
              onClick={handleChange}>
                { !isChanging && <>Change</>}
                { isChanging && <ClipLoader size={16} />}
              </button>
          </div>
        </div>
    </Modal>
  )
}

export default observer(ChangePaymentMethodModal)
