import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import { Link } from "react-router-dom";
import SignInForm from '../../components/SignInForm';
import signInForm from '../../forms/signInForm';
import userStore from '../../stores/userStore';

const modelStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    backgroundColor       : '#e3f2ff',
  }
};

Modal.setAppElement('#root');

const SignInModal = () => {
  const [modalIsOpen, setIsOpen] = useState(true);
  const { user, clearAuthState } = userStore;

  useEffect(() => {
    return function cleanup() {
      clearAuthState();
    }
  })

  const closeModal = () => {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modelStyles}>
        <section className="section-login">
          <div className="section-login__content">
          <h1 className="section-login__heading">Sign In</h1>
            { !user &&
              <>
                <SignInForm form={signInForm} />
                <Link className="section-login__forgot-password" to="/reset-password">Forgot password?</Link>
              </>
            }
          </div>
        </section>
    </Modal>
  )
}

export default observer(SignInModal)
