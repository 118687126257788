import React from "react";
import styles from "./InvoicesList.module.scss";

function getDateAndTime(created_at) {
	const fullDate = new Date(created_at);

	const year = fullDate.getFullYear();
	const month = String(fullDate.getMonth() + 1).padStart(2, "0");
	const day = String(fullDate.getDate()).padStart(2, "0");

	const date = `${day}.${month}.${year}`;

	return String(date);
}

function InvoicesList({ activeProductData }) {
	const items = activeProductData.filter((item) => {
		return item.status === "paid" || item.status === "open";
	});

	return (
		<ul className={styles.list}>
			<li className={styles.header}>
				<span className={styles.date}>Date</span>
				<span className={styles.total}>Invoice total</span>
				<span className={styles.status}>Status</span>
				<span className={styles.link}>Invoice</span>
			</li>
			{items.map(({ amount, currency, period_end, status, invoice_link }, idx) => {
				return (
					<li key={idx} className={styles.item}>
						<span className={styles.date}>{getDateAndTime(period_end * 1000)}</span>
						<span className={styles.total}>
							{amount / 100}
							{currency === "eur" ? "€" : "$"}
						</span>
						<span className={styles.status}>{status}</span>
						<span className={styles.link}>
							<a href={invoice_link}>
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M2.646 5.97202L7.46461 11.4685C7.76029 11.8058 8.23971 11.8058 8.53539 11.4685L13.354 5.97202C13.6497 5.63473 13.6497 5.08789 13.354 4.75058C13.0583 4.4133 12.5789 4.4133 12.2832 4.75058L9.2742 8.18289C9.08339 8.40053 8.75717 8.2464 8.75717 7.93859V0.863683C8.75717 0.386685 8.41818 0 8 0C7.58182 0 7.24283 0.386685 7.24283 0.863683V7.93859C7.24283 8.24638 6.91661 8.40053 6.7258 8.18289L3.7168 4.75058C3.42112 4.4133 2.9417 4.4133 2.646 4.75058C2.35032 5.08789 2.35032 5.63473 2.646 5.97202Z"
										fill="#0466AD"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M2.66667 14.779C1.86334 14.779 1.21212 14.123 1.21212 13.3138V10.4089C1.21212 10.0717 0.940778 9.79841 0.60606 9.79841C0.271342 9.79841 0 10.0717 0 10.4089V14.5348C0 15.344 0.651223 16 1.45455 16H14.5455C15.3488 16 16 15.344 16 14.5348V10.4089C16 10.0717 15.7287 9.79841 15.3939 9.79841C15.0592 9.79841 14.7879 10.0717 14.7879 10.4089V13.3138C14.7879 14.123 14.1367 14.779 13.3333 14.779H2.66667Z"
										fill="#0466AD"
									/>
								</svg>
								Download
							</a>
						</span>
					</li>
				);
			})}
		</ul>
	);
}

export default InvoicesList;
