import { observable, action, decorate, flow } from 'mobx';
import api from '../api/http';
import { reCaptchaExecute } from 'recaptcha-v3-react-function-async';

class PlansStore {
  plans = [];
  plan = null;
  coupon = null;
  priceWithCoupon = 0;

  getPlans = () => flow(function* (self) {
    const resp = yield api.getPlans();
    self.plans = resp.data;
  })(this)

  getPlan = (id) => flow(function* (self) {
    const resp = yield api.getPlan(id);
    self.plan = resp.data;
  })(this)

  payPlan = (id, data) => flow(function* () {
    let ReCaptchaToken = yield reCaptchaExecute('6LenHF0jAAAAAMhM_bzKVx5L368svi7pk1GbqDQe', 'SignUp')
    data = { ...data, recaptcha_token: ReCaptchaToken }

    return api.payPlan(id, data);
  })(this)

  checkCoupon = (id) => {
    return api.checkCoupon(id);
  }

  applyCoupon = (coupon, amount) => {
    this.coupon = coupon;
    if (coupon.type === "percent") {
      this.priceWithCoupon = (amount - amount * coupon.amount / 100).toFixed(2)
    } else {
      this.priceWithCoupon = (amount - coupon.amount / 100).toFixed(2)
    }
  }

  clearCoupon = () => {
    this.coupon = null;
    this.priceWithCoupon = 0;
  }
}

decorate(PlansStore, {
  plans: observable,
  plan: observable,
  priceWithCoupon: observable,
  getPlans: action,
  getPlan: action,
  payPlan: action,
  checkCoupon: action,
  applyCoupon: action,
});

export default new PlansStore();