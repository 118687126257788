import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { eq, get } from 'lodash/fp';
import HttpStatus from 'http-status-codes';
import {
  Form,
  Button,
  Upload,
  Input,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import licensesStore from '../../stores/licensesStore';
// import { Redirect } from 'react-router-dom';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 24,
  },
};

const ManualActivation = ({ form }) => {
  const { manualActivation } = licensesStore;
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileList, setFileList] = useState([]);

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };

  const uploaderProps = {
    name: 'req_file',
    showUploadList: false,
    accept: "text/xml",
    beforeUpload: (file) => {
      setFileList([file])
      const reader = new FileReader();
      reader.readAsText(file);
      reader.addEventListener('load', (event) => {
        form.setFieldsValue({req_xml: event.target.result});
      })
      return false;
    },
    fileList: fileList
  }

  const activate = useCallback(async () => {
    setIsProcessing(true);
    try {
      const reqXML = form.getFieldValue('req_xml');
      const resp = await manualActivation(reqXML);
      const { url } = resp.data;
      form.setFieldsValue({req_xml: ""});
      url && window.open(url, "_blank");
    } catch (e) {
      console.log(e);
      if (eq(HttpStatus.BAD_REQUEST, get('response.status')(e))) {
        console.log(e);
        let errMsg = get('response.data.message')(e);
        if (!errMsg) {
          errMsg = get('response.data.error')(e);
        }
        toast.error(errMsg);
      }
    }
    setIsProcessing(false);
  }, [manualActivation, form]);

  return (
    <Form layout="vertical" {...formItemLayout} form={form} onFinish={onFinish}>
      <Form.Item label="Upload the request file">
        <Upload {...uploaderProps}>
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item 
        name="req_xml" 
        label="Or, paste the request"
        rules={[
          {
            required: true,
            message: 'Required',
          },
        ]}>
        <Input.TextArea rows={15} onChange={(e) => console.log(e)}/>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isProcessing} onClick={activate}>Activate</Button>
      </Form.Item>
    </Form>
  )
}

export default observer(ManualActivation);
