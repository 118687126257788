import React from "react";

function ServerLog({ logData }) {
	return (
		<div className="section-licenses-card__log-information">
			<div className="code_wrapper">
				<code>
					{logData.map((item) => (
						<React.Fragment key={item}>
							{item}
							<br />
							<br />
						</React.Fragment>
					))}
				</code>
			</div>
		</div>
	);
}

export default ServerLog;
