import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import licensesStore from "../../stores/licensesStore";
import cancelLicenseForm from '../../forms/cancelLicenseForm';

const CancelLicenseContainer = () => {
  const form = cancelLicenseForm;
  const history = useHistory();
  const { cancelLicense } = licensesStore;
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramKey = query.get('key');

  useEffect(() => {
    paramKey && form.update({key: paramKey});
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { key } = form.values();
    if (key === "") {
      toast.error("Enter the key");
    } else {
      cancelLicense({key});
      history.push('/');
    }
  }

  return (
    <div className="wrapper">
      <section className="section-cancel">
        <div className="u-center-text u-margin-bottom-big">
          <h2 className="heading-secondary heading-secondary--border">Unsubscribe</h2>
        </div>
        <p className="paragraph paragraph--center paragraph--smallmargin">Are you saying this is the end?! After all these great times together?!</p>
        <p className="paragraph paragraph--center paragraph--smallmargin">Well it’s a free country and we can’t stop you, but are you completely sure?</p>
        <p className="paragraph paragraph--center paragraph--smallmargin">Don’t forget, if you unsubscribe the license key will be immediately revoked.</p>
        <p className="paragraph paragraph--center paragraph--smallmargin">You can, of course, subscribe again in the future, but there will be no second trial period.</p>
        <form className="section-cancel__form">
          <div className="section-cancel__form--content">
            <div className="section-cancel__form--reason" id="section-cancel__form--reason">
              <label htmlFor="reason" className="heading-tertiary">Please let us know a reason</label>
              <select form="section-cancel__form" id="reason" required="">
                <option hidden="" value=""> -- select an option -- </option>
                <option value="I have got a better tool">I have got a better tool</option>
                <option value="I do not need it any more">I do not need it any more</option>
                <option value="It is too expensive">It is too expensive</option>
                <option value="I was looking something else">I was looking something else</option>
              </select>
            </div>
            <div className="section-cancel__form--key">
              <label htmlFor="key" className="section-cancel__form--label heading-tertiary">Key</label>
              <input 
                className="key StripeElement section-cancel__form--key-input" 
                maxLength="35" {...form.$('key').bind()}/>
            </div>
          </div>
          <button 
            type="button" 
            className="section-cancel__form--button"
            onClick={handleSubmit}>Submit</button>
        </form>
      </section>
    </div>
  )
}

export default observer(CancelLicenseContainer);
