import { observable, action, decorate, flow } from 'mobx';
import api from '../api/http';

class AppStore {
  appInfo = {};
  appIsLoad = false

  getAppInfo = () => flow(function* (self) {
    const resp = yield api.getAppInfo();
    self.appInfo = resp.data;
  })(this)

  setAppIsLoad = () => flow(function* (self) {
    yield self.appIsLoad = true;
  })(this)
}

decorate(AppStore, {
  appInfo: observable,
  appIsLoad: observable,
  getAppInfo: action,
  setAppIsLoad: action
});

export default new AppStore();