import React from "react";
import styles from "./Modal.module.scss";
import CheckIcon from "./CheckIcon";

function LeavingModal({ closeModal, acceptModal }) {
	const [valueArr, setValueArr] = React.useState([]);
	const [otherValue, setOtherValue] = React.useState("");

	const modalRef = React.useRef();

	React.useEffect(() => {
		const clickHandler = (e) => {
			if (e.composedPath().includes(modalRef.current)) {
				return;
			}
			closeModal();
		};

		document.body.addEventListener("mousedown", clickHandler);

		return () => {
			document.body.removeEventListener("mousedown", clickHandler);
		};
	}, [closeModal]);

	const handleAcceptModalFormModal = () => {
		let arr = [...valueArr].map((item) => {
			if (item === "Other") {
				item = otherValue;
			}
			return item;
		});

		acceptModal(arr);
	};

	const inputOnChange = ({ target }) => {
		let { value, checked } = target;
		let newValueArr = [...valueArr];

		if (!checked) {
			newValueArr = newValueArr.filter((item) => item !== value);
		}
		if (checked) {
			newValueArr = [...newValueArr, value];
		}
		setValueArr(newValueArr);
	};

	const inputIsChecked = (value) => {
		return valueArr.includes(value);
	};

	return (
		<div className={styles.overlay}>
			<div ref={modalRef} className={styles.modal}>
				<div className={styles.content}>
					<h2 className={styles["content-title"]}>Before you go, please let us know why</h2>
					<form className={styles.form}>
						<fieldset className={styles.fieldset}>
							<legend className={styles.legend}>What made you cancel your subscription?</legend>
							<label className={`${styles.label} ${styles.checkbox_label}`}>
								<input
									className={styles.input}
									type="checkbox"
									onChange={inputOnChange}
									value="No longer using VQ Analyzer / VQ Probe"
									checked={inputIsChecked("No longer using VQ Analyzer / VQ Probe")}
								/>
								<CheckIcon />
								No longer using VQ Analyzer / VQ Probe
							</label>
						</fieldset>
						{inputIsChecked("No longer using VQ Analyzer / VQ Probe") && (
							<fieldset className={styles.fieldset}>
								<legend className={styles.legend}>What products /services will you be using instead, if any?</legend>
								<label className={`${styles.label} ${styles.checkbox_label}`}>
									<input
										className={styles.input}
										type="checkbox"
										onChange={inputOnChange}
										value="Switching to a different plan"
										checked={inputIsChecked("Switching to a different plan")}
									/>
									<CheckIcon />
									Switching to a different plan
								</label>
								<label className={`${styles.label} ${styles.checkbox_label}`}>
									<input
										className={styles.input}
										type="checkbox"
										onChange={inputOnChange}
										value="Project is over or no longer active"
										checked={inputIsChecked("Project is over or no longer active")}
									/>
									<CheckIcon />
									Project is over or no longer active
								</label>
								<label className={`${styles.label} ${styles.checkbox_label}`}>
									<input
										className={styles.input}
										type="checkbox"
										onChange={inputOnChange}
										value="Too expensive"
										checked={inputIsChecked("Too expensive")}
									/>
									<CheckIcon />
									Too expensive
								</label>
								<label className={`${styles.label} ${styles.checkbox_label}`}>
									<input
										className={styles.input}
										type="checkbox"
										onChange={inputOnChange}
										value="Other"
										checked={inputIsChecked("Other")}
									/>
									<CheckIcon />
									Other
								</label>
								{inputIsChecked("Other") && (
									<label className={styles.label}>
										<input
											className={styles.input}
											type="text"
											onChange={({ target }) => {
												setOtherValue(target.value);
											}}
											value={otherValue}
											placeholder="Please, specify"
										/>
									</label>
								)}
							</fieldset>
						)}
					</form>
				</div>
				<div className={styles.options}>
					<button className={styles["options-close"]} onClick={() => closeModal()}>
						GoBack
					</button>
					<button onClick={handleAcceptModalFormModal} className={styles["options-accept"]}>
						Continue
					</button>
				</div>
			</div>
		</div>
	);
}

export default LeavingModal;
