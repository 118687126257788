import { HandySvg } from "handy-svg";
import React from "react";
import userStore from "../../stores/userStore";
import styles from "./ProductItem.module.scss";

function ItemHeader({
	license,
	handleOpenFormModal,
	handleChangePaymentMethod,
	handleMenuToggle,
	menuOpen,
	goToManualActivation,
	handleToggleLog,
	logData,
	handleShareLicense,
}) {
	const { product_name, is_enterprise, product_mode, status, is_free, owner, current_period_end } = license;
	const { user } = userStore;

	const isEnterprise = () => {
		return is_enterprise;
	};

	const isActiveLicense = () => {
		return status === "active" || status === "trialing";
	};

	const isCancelsLicense = () => status === "canceled";

	return (
		<div className={styles["item-header"]}>
			<h2 className={styles.title}>
				<span>
					{product_name} {product_mode}
				</span>
				<span className={`${styles["status"]} ${isActiveLicense() ? styles["active"] : styles["canceled"]}`}>
					{status}
				</span>
				{owner !== user.email && <span className={`${styles["status"]} ${styles["shared"]}`}>shared</span>}
			</h2>
			{isActiveLicense() && (
				<div className={styles.options}>
					{owner === user.email && (
						<>
							<button onClick={handleOpenFormModal} className={styles["options-btn"]} title="Cancel subscription">
								<HandySvg width={22} height={22} src={"/static/cancel.svg"} />
							</button>

							{!is_free && !isEnterprise() && (
								<button
									onClick={handleChangePaymentMethod}
									className={styles["options-btn"]}
									title="Change payment method"
								>
									<HandySvg width={22} height={22} src={"/static/change.svg"} />
								</button>
							)}
						</>
					)}
					<button onClick={handleMenuToggle} className={styles["options-btn"]} title="Menu open">
						<HandySvg width={22} height={22} src={"/static/option_menu.svg"} />
					</button>
					<div
						className={`options-menu ${styles["options-menu"]} ${
							menuOpen ? styles["options-menu_open"] : styles["options-menu_close"]
						}`}
					>
						<button onClick={handleMenuToggle} className={styles["options-close_btn"]} title="Menu close">
							<HandySvg width={14} height={14} src={"/static/close.svg"} />
						</button>
						<ul className={styles["options-list"]}>
							{owner === user.email && (
								<>
									<li>
										<button className={styles["options-list_item"]} onClick={handleOpenFormModal}>
											<HandySvg width={12} height={12} src={"/static/cancel.svg"} />
											Cancel subscription
										</button>
									</li>
									{!is_free && !isEnterprise() && (
										<li>
											<button className={styles["options-list_item"]} onClick={handleChangePaymentMethod}>
												<HandySvg width={12} height={12} src={"/static/change.svg"} />
												Change payment method
											</button>
										</li>
									)}
								</>
							)}
							{isActiveLicense() && isEnterprise() && (
								<li>
									<button className={styles["options-list_item"]} onClick={goToManualActivation}>
										<HandySvg width={12} height={12} src={"/static/key.svg"} />
										Manual Activation
									</button>
								</li>
							)}
							{(isActiveLicense() || (isCancelsLicense() && new Date(current_period_end) >= new Date())) &&
								license.server &&
								license.server.ip_address && (
									<li>
										<button className={styles["options-list_item"]} onClick={handleToggleLog}>
											<HandySvg width={12} height={12} src={"/static/cmd.svg"} />
											{logData.length === 0 ? "Get server logs" : "Close server logs"}
										</button>
									</li>
								)}

							{owner === user.email && !is_free && (
								<li>
									<button className={styles["options-list_item"]} onClick={handleShareLicense}>
										<HandySvg width={12} height={12} src={"/static/share.svg"} /> Share license
									</button>
								</li>
							)}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
}

export default ItemHeader;
