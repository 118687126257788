import MobxReactForm from "mobx-react-form";
import dvr from 'mobx-react-form/lib/validators/DVR';
import { toast } from "react-toastify";
import validatorjs from 'validatorjs';
import userStore from '../stores/userStore';

const plugins = {
  dvr: dvr(validatorjs)
}

const fields = [{
  name: 'company',
  label: 'Company',
  placeholder: '',
  rules: 'string',
  value: '',
  type: 'company'
}]

const hooks = {
  onSuccess(form) {
    const { changeCompany } = userStore;
    const values = form.values();
    if (values.company === "") {
      toast.error("Enter company name");
    }
    changeCompany(values);
    form.update({ company: "" });
  },
  onError(form) {}
}

export default new MobxReactForm({ fields }, { plugins, hooks });