import React, { useEffect, useCallback, useState } from "react";
import styles from "./ProductsList.module.scss";
import ProductItem from "../ProductItem";
import licensesStore from "../../stores/licensesStore";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { observer } from "mobx-react";
import { HandySvg } from "handy-svg";

import ChangePaymentMethodModal from "../ChangePaymentMethodModal";
import checkoutForm from "../../forms/checkoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function ProductsList() {
	const { getLicenses, licenses, pmLicense } = licensesStore;
	const [sortLicenses, setSortLicenses] = useState("");

	const fetchLicenses = useCallback(async () => {
		await getLicenses();
	}, [getLicenses]);

	useEffect(() => {
		fetchLicenses();
	}, [fetchLicenses]);

	useEffect(() => {
		const isActiveLicense = ({ status }) => {
			return status === "active" || status === "trialing";
		};

		if (licenses) {
			let newArr = JSON.parse(JSON.stringify(licenses));
			newArr.sort((a, b) => {
				if (!isActiveLicense(a) && isActiveLicense(b)) {
					return 1;
				}

				if (isActiveLicense(a) && !isActiveLicense(b)) {
					return -1;
				}

				return 0;
			});
			setSortLicenses(newArr);
		}
	}, [licenses, setSortLicenses]);

	return (
		<>
			{sortLicenses.length > 0 && (
				<Elements stripe={stripePromise}>
					<div className={styles.list}>
						{sortLicenses
							? sortLicenses
									.filter((license) => license.status !== "invisible" && license.status !== "incomplete")
									.map((license, index) => {
										return (
											<React.Fragment key={`${license.key}${index}`}>
												<ProductItem {...license} licenseKey={license.key} />
											</React.Fragment>
										);
									})
							: "no licenses"}
						{pmLicense && <ChangePaymentMethodModal form={checkoutForm} license={pmLicense} />}
					</div>
				</Elements>
			)}
			{sortLicenses.length < 1 && (
				<div className={styles.empty}>
					<HandySvg width={199} height={194} src={"/static/blank-tablet.svg"} />
					Your product list is empty
				</div>
			)}
		</>
	);
}

export default observer(ProductsList);
