import React from 'react';
import { observer } from 'mobx-react';
import './index.css';

const DoYouHaveAQuestionBanner = () => {
  return (
    <div className="DoYouHaveAQuestionBanner">
      <div className="DoYouHaveAQuestionBanner-text-container">
        <div className="DoYouHaveAQuestionBanner-text-container__main-text">
           Do you have questions?
        </div>
        <div className="DoYouHaveAQuestionBanner-text-container__sub-text">
           Contact us to discuss your requirements
        </div>
        <a className="DoYouHaveAQuestionBanne-button" href="mailto:info@vicuesoft.com">Contact us</a>
      </div>
    </div>
  )
}

export default observer(DoYouHaveAQuestionBanner);
