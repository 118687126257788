import React from 'react';
import { Form } from 'antd';
import ManualActivation from "../../components/ManualActivation";

const ManualActivationContainer = () => { 
  const [form] = Form.useForm();

  return (
    <div className="private-pages__wrapper">
      <div className="section-licenses">
        <h1>Manual Activation</h1>
        <ManualActivation form={form} />
      </div>
    </div>
  )
}

export default ManualActivationContainer