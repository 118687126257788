import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link, useHistory, useLocation } from "react-router-dom";
import userStore from "../../stores/userStore";
import "./index.css";
import * as ga from "../../seo/ga";
import appStore from "../../stores/appStore";
import { HandySvg } from "handy-svg";

const Header = () => {
	const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);
	const { user, signOut } = userStore;
	const pathname = useLocation().pathname;
	const history = useHistory();
	const { appIsLoad } = appStore;

	const clickHandler = (e) => {
		if (
			!e.target.matches(".Header__user_menu") &&
			!e.target.matches(".Header__user_menu *") &&
			!e.target.matches(".Header__user_btn") &&
			!e.target.matches(".Header__user_btn *")
		) {
			setUserMenuIsOpen(false);
		}
	};

	useEffect(() => {
		ga.pageview(pathname);

		document.body.addEventListener("mousedown", clickHandler);
		return () => {
			document.body.removeEventListener("mousedown", clickHandler);
		};
	}, [pathname]);

	const toggleUserMenu = () => {
		setUserMenuIsOpen(!userMenuIsOpen);
	};

	const signOutInit = () => {
		setUserMenuIsOpen(false);
		signOut()
		history.push("/")
	}

	const handleBack = () => {
		(pathname !== '/thankyou' && pathname !== '/profile-confirm') ? history.goBack() : history.push("/")
	}


	return (
		<header className={`Header ${pathname === "/profile"? 'profile' : 'not_profile'}` }>
			<nav className="Header__content">
				{pathname === "/" && (
					<a
						href="https://vicuesoft.com/"
						target="_blank"
						rel="noopener noreferrer"
						title="ViCueSoft corporate website"
					>
						<HandySvg width={96} height={44} src={"/static/logo-vicuesoft-white-full.svg"} className="Header-vicuesoft-logo" />
					</a>
				)}

				{pathname !== "/" && pathname !== "/profile" && (
					<button onClick={handleBack} className="Header__link">
						<span>←</span> Back
					</button>
				)}

				<div className="Header__right">
					{user && appIsLoad && (
						<>
							<div className="Header__twoline">
								<button
									onClick={toggleUserMenu}
									to="/profile"
									className={`Header__user_btn ${userMenuIsOpen
										? "Header__user_btn__open"
										: "Header__user_btn__close"
										}`}
								>
									{`${user.first_name.slice(0, 1)}${user.last_name.slice(0, 1)}`}
								</button>
								<div className={`Header__user_menu`}>
									<Link to="/profile?tab=general" onClick={toggleUserMenu} className="Header_user_menu__btn">
										<span>General</span>
									</Link>
									<Link to="/profile?tab=settings" onClick={toggleUserMenu} className="Header_user_menu__btn">
										<span>Settings</span>
									</Link>
									<Link to="/profile?tab=billing_and_payments" onClick={toggleUserMenu} className="Header_user_menu__btn">
										<span>Billing  & Payments</span>
									</Link>
									<button className="Header_user_menu__btn" onClick={signOutInit}>
										<span>Sign out</span>
									</button>
								</div>
							</div>
						</>
					)}
					{!user && appIsLoad && (
						<>
							<Link to="/sign-in" className="Header__button">
								Sign in
							</Link>
							<Link to="/sign-up" className="Header__button">
								Sign up
							</Link>
						</>

					)}
				</div>
			</nav>
		</header>
	);
};

export default observer(Header);
