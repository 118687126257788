import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link, Redirect } from "react-router-dom";
import SignInForm from '../../components/SignInForm';
import signInForm from '../../forms/signInForm';
import userStore from '../../stores/userStore';

const SignInContainers = () => {
  const { user, clearAuthState } = userStore;

  useEffect(() => {
    return function cleanup() {
      clearAuthState();
    }
  })

  return (
    <div className="private-pages__wrapper private-pages__wrapper--center">
      <section className="section-login">
        <h1 className="section-login__heading">Sign In</h1>
        <div className="section-login__content">
          { user && <Redirect to="/" push /> }
          { !user &&
            <>
              <SignInForm form={signInForm} />
              <Link className="section-login__forgot-password" to="/reset-password">Forgot password?</Link>
            </>
          }
          <div>Don't have an account? <Link to="/sign-up">Sign up</Link></div>
        </div>
      </section>
    </div>
  ) 
};

export default observer(SignInContainers)