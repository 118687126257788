import React, { useState } from "react";
import userStore from "../../stores/userStore";
import SmallModal from "../Modal/SmallModal";
import styles from "./SettingsForm.module.scss";
import changeCompanyForm from "../../forms/changeCompanyForm";
import changePasswordForm from "../../forms/changePasswordForm";
import { observer } from "mobx-react";
import { toast } from "react-toastify";

function SettingsForm() {
	const { user, checkingPossibilityUserDelete, deleteUser } = userStore;
	const [userName, setUserName] = useState(user.email);
	const [companyName, setCompanyName] = useState(user.company);
	const [password, setPassword] = useState("");
	const [confirm, setConfirm] = useState("");
	const [error, setError] = useState({
		name: "",
		companyName: "",
		password: "",
		confirm: "",
	});
	const [deleteModalIsShown, setDeleteModalIsShown] = useState(false);

	const closeDeleteModal = () => {
		setDeleteModalIsShown(false);
	};

	const formValidation = (e) => {
		e.preventDefault();
		setError({ name: "", companyName: "", password: "", confirm: "" });

		if (password.length > 0) {
			if (password.trim().length > 0 && password.trim() === confirm.trim()) {
				changePasswordForm.$("new_password").set(password);
				changePasswordForm.onSubmit(e);
				setPassword("");
				setConfirm("");
			} else {
				setError(() => {
					return {
						...error,
						confirm: "Passwords don't match",
					};
				});
			}
		}
		if (companyName !== user.company) {
			changeCompanyForm.$("company").set(companyName);
			changeCompanyForm.onSubmit(e);
		}
	};

	const checkingForChanges = () => {
		return companyName !== user.company || (password.trim().length > 0 && confirm.trim().length);
	};

	const checkingPossibilityDelete = async () => {
		let res = await checkingPossibilityUserDelete();
		let status = res?.status;
		let data = res?.data;

		if (status === 200 && data.allowed) {
			setDeleteModalIsShown(true);
			return;
		}

		toast.error(data.message ? data.message : "something wrong");
	};

	const deleteUserInit = () => {
		deleteUser({ message: '' });
	};

	return (
		<>
			<form
				onSubmit={(e) => {
					formValidation(e);
				}}
				className={styles.form}
			>
				<div className={styles["form-box"]}>
					<label className={styles.label} htmlFor="name">
						Email
					</label>
					<input
						value={userName}
						onChange={(e) => setUserName(e.target.value)}
						className={styles.input}
						name="name"
						type="text"
						id="name"
						disabled
					/>
					<p className={styles.error}></p>
				</div>
				<div className={styles["form-box"]}>
					<label className={styles.label} htmlFor="company">
						Company name
					</label>
					<input
						className={styles.input}
						name="company"
						type="text"
						value={companyName}
						onChange={(e) => setCompanyName(e.target.value)}
						id="company"
					/>

					<p className={styles.error}></p>
				</div>
				<div className={styles["form-box"]}>
					<label className={styles.label} htmlFor="password">
						New password
					</label>
					<input
						className={styles.input}
						name="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						type="password"
						id="password"
					/>
					<p className={styles.error}></p>
				</div>
				<div className={styles["form-box"]}>
					<label className={styles.label} htmlFor="confirm">
						Confirm password
					</label>
					<input
						className={styles.input}
						name="confirm"
						value={confirm}
						onChange={(e) => setConfirm(e.target.value)}
						type="password"
						id="confirm"
					/>
					<p className={styles.error}>{error.confirm}</p>
				</div>

				<div className={styles.options}>
					<button type="button" onClick={checkingPossibilityDelete} className={styles["delete-btn"]}>
						Delete account
					</button>
					<button className={styles.btn} type="submit" disabled={!checkingForChanges()}>
						Change
					</button>
				</div>
			</form>
			{deleteModalIsShown && (
				<SmallModal
					closeModal={closeDeleteModal}
					title={"Delete account"}
					closeBtn={"Cancel"}
					acceptBtn={"Delete"}
					acceptCallback={deleteUserInit}
				>
					Are you sure you want to delete your account? This will permanently erase your account.
				</SmallModal>
			)}
		</>
	);
}

export default observer(SettingsForm);
