import React from 'react';
import { observer } from 'mobx-react';
import './index.css';
import FrequentlyAskedQuestionsItem from '../FrequentlyAskedQuestionsItem';

const FrequentlyAskedQuestions = () => {
  return (
    <div className="FrequentlyAskedQuestions">
      <div className="FrequentlyAskedQuestions-text-container">
        Frequently asked questions
      </div>
      <div className="FrequentlyAskedQuestions-content-container">
        <FrequentlyAskedQuestionsItem visibleText="If I Lost My License Key. Where Can I Find It?" notVisibleText={<>Things happen. Send us a email <a href='mailto:info@vicuesoft.com'>info@vicuesoft.com</a>, we will provide all details.</>} />
        <FrequentlyAskedQuestionsItem visibleText="I Have Got A Key. What To Do Next" notVisibleText="A Key supposed only for Locked license. After VQ Analyzer installation, Simple go to Help-->Activate menu. Chose 'License Key' radio button, enter received Key and push 'Activate' button. Make sure you are online. If you succeed in activation you will see green 'activated' status at the top the dialog window." />
        <FrequentlyAskedQuestionsItem visibleText="I Have Got Floating License Credentials. What To Do Next" notVisibleText="An IP address and port supposed only for Float license. Make sure you are online. After VQ Analyzer installation, simple go to Help-->Activate menu. Choose 'Floating license' radio button, enter received IP and port and push 'Activate' button. License Key will be needed only for subscription cancelation. If you succeed in activation you will see green 'activated' status at the top the dialog window." />
        <FrequentlyAskedQuestionsItem visibleText="I Have Got Error During Activation A Locked Key On My Windows 10 Pro" notVisibleText={<>Locked Key is not allowed to be activated on a Virtual Machine. Windows Pro may have enabled hypervisor function by default. If you want to keep it enabled than your choice is a Float plan. You can disable hypervisor by command <span className="bold">bcdedit /set hypervisorlaunchtype off</span>, and after reboot your Locked Key will be ready to be activated.</>} />
        {/* <FrequentlyAskedQuestionsItem visibleText="If I Want To Reinstall VQ Analyzer AVC One?" notVisibleText={<>Locked license is supposed for a computer you initially activated with. There is a limited number of reinstalls, usually you can do it at least once. Send us a email <a href='mailto:info@vicuesoft.com'>info@vicuesoft.com</a>, we will do our best to help. With Float plans you will avoid such troubles.</>} /> */}
        <FrequentlyAskedQuestionsItem visibleText="May I Get Multiple Subscriptions For A Single Account?" notVisibleText="Yes, you can do it. You may use the same email and payment method to subscribe multiple times." />
        <FrequentlyAskedQuestionsItem visibleText="How Can I Cancel My Subscription?" notVisibleText={<>You can cancel your subscription at any time by entering your License Key at <a href="/cancel">https://portal.vicuesoft.com/cancel</a>, you have to be online and License Manager web server has to be reachable for the action. Portal will ask no questions, your further Subscription will be cancelled immediately but License Key will be valid until end of prepaid period. You will get a email from us to confirm your cancellation. Make sure that you understand our <a href="https://vicuesoft.com/terms-of-use">Refund policy</a> before cancelation.</>} />
        <FrequentlyAskedQuestionsItem visibleText="If I Want A Long Term Contract?" notVisibleText={<>VQ Subscription is a pay-as-you-go service. You simply pay month-to-month. If you cancel, you'll be billed for the current month, but you won't be billed again. If you are looking for long term contracts you may want VQ Analyzer product, then shot us an email <a href="mailto:sales@vicuesoft.com">sales@vicuesoft.com</a>.</>} />
        {/* <FrequentlyAskedQuestionsItem visibleText="What Is The Difference Between One And Float Plans?" notVisibleText="There is feature difference such as command line support in Float plan. With Float plan you can install multiple Analyzer on different computers in different locations, including virtual machines (Locked prohibits VM). Float Analyzer can be installed on your Win Desktop, MacBook or you Linux server (while Locked is intended only for Windows OS). You may connect from one of them at the moment." /> */}
        <FrequentlyAskedQuestionsItem visibleText="Do you have VVC?" notVisibleText={<>Yes, we have it, ask <a href="mailto:sales@vicuesoft.com">sales@vicuesoft.com</a> for details.</>} />
        <FrequentlyAskedQuestionsItem visibleText="I am A Student/University Professor" notVisibleText={<>We have got special rates for you, send us a email to <a href="mailto:sales@vicuesoft.com">sales@vicuesoft.com</a> for more detail.</>} />
        <FrequentlyAskedQuestionsItem visibleText="How Can I Use A Coupon/Promocode?" notVisibleText="You can apply your coupon at checkout page. You will see a new price before placing the order. Make sure that coupon name is case sensitive." />
        <FrequentlyAskedQuestionsItem visibleText="How Can I Pay?" notVisibleText={<>We accept all major credit and debit cards: Visa, American Express, MasterCard, Discover, and JCB. We do not accept PayPal. If you need to pay by other means, then shot us an email <a href="mailto:sales@vicuesoft.com">sales@vicuesoft.com.</a></>} />
        <FrequentlyAskedQuestionsItem visibleText="How The Free Trial Works?" notVisibleText="If you cancel a paying plan within 3 or 7 days (depends on products) of signing up you won't be charged a thing. If you do choose to cancel your account in the future you will never be charged again, but you are responsible for charges already incurred up until your cancellation. Refunds cannot be issued after your initial 3 or 7 days trial and we don't prorate for partial months." />
        <FrequentlyAskedQuestionsItem visibleText="Do I Get A 3-Day / 7-Day  Free Trial If I Upgrade?" notVisibleText="If you change a plan you are not eligible for additional 3-day / 7-day  free trial. The 3-day/7-day free trial on a plans only applies if you sign up for a paying plan now. You can always downgrade later if you choose." />
      </div>
    </div>
  )
}

export default observer(FrequentlyAskedQuestions);
