import React from "react";
import { observer } from "mobx-react";
import userStore from "../../stores/userStore";
import { Redirect, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import AccountMenu from "../../components/AccountMenu";
import ProductsList from "../../components/ProductsList";
import SettingsForm from "../../components/SettingsForm";
import PaymentInfo from "../../components/PaymentInfo";
import appStore from "../../stores/appStore";

const UserProfileContainer = () => {
	const { appIsLoad } = appStore;

	const { user } = userStore;
	let { search } = useLocation();
	const [tab, setTab] = useState(null);

	useEffect(() => {
		const query = new URLSearchParams(search);
		const tab = query.get("tab");
		setTab(tab ? tab : "general");
	}, [search]);

	return (
		<>
			{appIsLoad && !user && <Redirect to="/" push />}
			{user && (
				<div className="private-pages__wrapper private-pages__wrapper--center">
					<section className="section-account-details">
						<AccountMenu tab={tab} />
						{tab === null && "Loading"}
						{tab === "general" && <ProductsList />}
						{tab === "settings" && <SettingsForm />}
						{tab === "billing_and_payments" && <PaymentInfo />}
					</section>
				</div>
			)}
		</>
	);
};

export default observer(UserProfileContainer);
