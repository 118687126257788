import React from 'react';

const ThankYouContainer = () => {
  return (
    <>
      <section className="section-thank-you">
        <div className="section-thank-you__content">
          <div className="section-thank-you__content--text">
            <p className="paragraph paragraph--center">Your payment is being processed.</p>
            <p className="paragraph paragraph--center">We will shortly be sending you an email with all the details.</p>
            <p className="paragraph paragraph--center">The license key and download link will be in the email (provided payment is successful). If you are signing up for the first time, you will receive a three-day trial period.</p>
            <p className="paragraph paragraph--center">You can now close this page.</p>
          </div>
          <div className="section-thank-you__content--image">
            <img src="/static/email.svg" alt="Email" style={{ height: "20vh" }} />
          </div>
        </div>
      </section>
    </>
  )
}

export default ThankYouContainer;