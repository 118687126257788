import React from 'react';
import { observer } from 'mobx-react';
import userStore from '../../stores/userStore';
import './index.css';

const ResetPasswordForm = ({ form }) => {
  const { resetPasswordEmailNotFound, resetPasswordSent } = userStore;

  return (
    <>
      { resetPasswordSent && <
        p className="section-reset-password__content--text">New password has been sent to your email</p>}

      { !resetPasswordSent &&
        <>
          <p className="section-reset-password__content--text">Enter the email address associated with your account and we will send you a new password.</p>
          <form className="resetpassword">
            <div className="resetpassword__row">
              <label htmlFor={form.$('email').id} className="resetpassword__heading">{form.$('email').label}</label>
              <input className="resetpassword__input" {...form.$('email').bind()} />
              {form.$('email').error && <div className="form__input-error">{form.$('email').error}</div>}
              { resetPasswordEmailNotFound && <div className="form__input-error">User with specified email not found</div>}
            </div>

            <button
              className="resetpassword__button"
              type="button"
              onClick={form.onSubmit}>Reset</button>
          </form>
        </>
      }
    </>
  )
}

export default observer(ResetPasswordForm)
