import MobxReactForm from "mobx-react-form";
import dvr from 'mobx-react-form/lib/validators/DVR';
import { toast } from "react-toastify";
import validatorjs from 'validatorjs';
import userStore from '../stores/userStore';

const plugins = {
  dvr: dvr(validatorjs)
}

const fields = [{
  name: 'new_password',
  label: 'New Password',
  placeholder: '',
  rules: 'string',
  type: 'password'
}]

const hooks = {
  onSuccess(form) {
    const { changePassword } = userStore;
    const values = form.values()
    if (values.new_password === "") {
      toast.error("Enter new password")
    }
    changePassword(values);
    form.update({ new_passord: "" });
  },
  onError(form) {}
}

export default new MobxReactForm({ fields }, { plugins, hooks });