import { HandySvg } from "handy-svg";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import styles from "./ProductItem.module.scss";

function CopyBtn({text, onCopy}) {
	return (
		<CopyToClipboard text={text} onCopy={onCopy}>
			<button className={styles["info-copy"]}>
				<HandySvg width={20.86} height={24.02} src={"/static/clipboard.svg"} />
			</button>
		</CopyToClipboard>
	);
}

export default CopyBtn;
