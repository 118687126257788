import React, { useState, useCallback } from "react";
import licensesStore from "../../stores/licensesStore";
import styles from "./ProductItem.module.scss";
import { validateEmail } from "../../util";
import { HandySvg } from "handy-svg";

function Share({ emails, id }) {
	const { shareLicenseAdd, shareLicenseDel } = licensesStore;
	const [newShareUser, setNewShareUser] = useState("");
	const [emailErrorMsg, setEmailErrorMsg] = useState("");

	const handleShareLicenseAdd = useCallback(async () => {
		setEmailErrorMsg("");

		const isEmailValid = (value) => {
			if (!(value.length > 0)) {
				setEmailErrorMsg("required field");
				return false;
			}

			if (!validateEmail(value)) {
				setEmailErrorMsg("email entered incorrectly");
				return false;
			}

			return true;
		};

		if (isEmailValid(newShareUser)) {
			let data = {
				license: id,
				emails: [newShareUser],
			};

			shareLicenseAdd(data);
			setNewShareUser("");
		}
	}, [newShareUser, id, shareLicenseAdd]);

	const handleShareLicenseDel = (id, user) => {
		let data = {
			license: id,
			email: user,
		};

		shareLicenseDel(data);
	};

	return (
		<div className={styles.share}>
			<h3 className={styles.share_title}>Share access:</h3>
			<div className={styles["share_item-wrapper"]}>
				{emails.map((item) => (
					<div key={item} className={styles.share_item}>
						<div className={styles.share_input}>{item}</div>
						<button className={styles.share_btn} title="delete" onClick={() => handleShareLicenseDel(id, item)}>
							<HandySvg className={styles["btn-logo"]} width={16} height={16} src={"/static/delete.svg"} />
						</button>
					</div>
				))}
				<div className={styles.share_item}>
					<input
						type="email"
						value={newShareUser}
						className={styles.share_input}
						onChange={({ target }) => {
							setNewShareUser(target.value);
							setEmailErrorMsg("");
						}}
					></input>
					<button className={styles.share_btn} title="Add email to the shared access" onClick={handleShareLicenseAdd}>
						<HandySvg className={styles["btn-logo"]} width={16} height={16} src={"/static/add.svg"} />
					</button>
					{emailErrorMsg.length > 0 && <span className={styles.share_error}>{emailErrorMsg}</span>}
				</div>
			</div>
		</div>
	);
}

export default Share;
