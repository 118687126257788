import React from 'react';
import styles from './Modal.module.scss';

function SmallModal({ returnModal, closeModal, title, closeBtn, acceptBtn, children, acceptCallback }) {
  return (
    <div className={styles.overlay} onClick={closeModal}>
      <div className={styles.modal}>
        <div className={styles.content}>
          <h2 className={styles['content-title']}>{title}</h2>
          <p className={styles['content-text']}>{children}</p>
        </div>
        <div className={styles.options}>
          <button className={styles['options-close']} onClick={returnModal || closeModal}>{closeBtn}</button>
          <button onClick={acceptCallback} className={styles['options-accept']}>{acceptBtn}</button>
        </div>
      </div>
    </div>
  );
}
export default SmallModal;
