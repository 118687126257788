import React from 'react';
import { observer } from 'mobx-react';
import '../SignInForm/index.css';

const SignUpForm = ({ form }) => {
  return (
    <form className="signin">
      <div className="signin__row">
          <label htmlFor={form.$('email').id} className="signin__heading">{form.$('email').label}</label>
          <input className="signin__input" {...form.$('email').bind()} />
          {form.$('email').error && <div className="form__input-error">{form.$('email').error}</div>}
      </div>

      <div className="signin__row">
        <label htmlFor={form.$('first_name').id} className="signin__heading">{form.$('first_name').label}</label>
        <input className="signin__input" autoComplete="true" {...form.$('first_name').bind()} />
        {form.$('first_name').error && <div className="form__input-error">{form.$('first_name').error}</div>}
      </div>

      <div className="signin__row">
        <label htmlFor={form.$('last_name').id} className="signin__heading">{form.$('last_name').label}</label>
        <input className="signin__input" autoComplete="true" {...form.$('last_name').bind()} />
        {form.$('last_name').error && <div className="form__input-error">{form.$('last_name').error}</div>}
      </div>

      <div className="signin__row">
        <label htmlFor={form.$('country').id} className="signin__heading">{form.$('country').label}</label>
        <input className="signin__input" autoComplete="true" {...form.$('country').bind()} />
        {form.$('country').error && <div className="form__input-error">{form.$('country').error}</div>}
      </div>

      <button
        className="signin__button"
        type="button"
        onClick={form.onSubmit}>Sign up</button>
    </form>
  )
}

export default observer(SignUpForm);
