import React from "react";
import styles from "./NextInvoice.module.scss";
import { observer } from "mobx-react";

const formatCanceledAt = (d) => {
	const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sepr", "Oct", "Nov", "Dec"];
	if (d !== null) {
		const date = new Date(d * 1000);
		const month = monthNames[date.getMonth()];
		return `${month} ${date.getDate()}, ${date.getFullYear()}`;
	}
	return "indefinitely";
};

function NextInvoice({ activeProductData }) {

	let item = activeProductData.filter((item) => {
		return item.status === "draft";
	})[0];

	return (
		<>
			{item && (
				<div className={styles.info}>
					<div className={styles["info-item"]}>
						<p className={styles["info-title"]}>Next invoice issue date</p>
						<div className={styles["info-value"]}>
							<span>{formatCanceledAt(item.period_end)}</span>
						</div>
					</div>
					<div className={styles["info-item"]}>
						<p className={styles["info-title"]}>Invoice total</p>
						<div className={styles["info-value"]}>
							<span>{item.amount / 100}€</span>
						</div>
					</div>
					<div className={styles["info-item"]}>
						<p className={styles["info-title"]}>Monthly renewal</p>
						<div className={styles["info-value"]}>
							<span>{item.amount / 100}€</span>
						</div>
					</div>
				</div>
			)}
			{item && <h2>Next payment not scheduled</h2>}
		</>
	);
}

export default observer(NextInvoice);
