import MobxReactForm from "mobx-react-form";
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

const plugins = {
  dvr: dvr(validatorjs)
}

const fields = [{
  name: 'email',
  label: 'Business email',
  placeholder: '',
  rules: 'required|email|string',
  value: '',
  type: 'email'
}, {
  name: 'first_name',
  label: 'First name',
  placeholder: '',
  rules: 'required|string',
  value: ''
}, {
  name: 'last_name',
  label: 'Last name',
  placeholder: '',
  rules: 'required|string',
  value: ''
}, {
  name: 'country',
  label: 'Country',
  placeholder: '',
  rules: 'required|string',
  value: ''
}, {
  name: 'company',
  label: 'Company',
  placeholder: '',
  rules: 'required|string',
  value: ''
}, {
  name: 'comments',
  label: 'Comments',
  placeholder: '',
  rules: 'string',
  value: ''
}]

const hooks = {
  onSuccess(form) {},
  onError(form) {}
}

export default new MobxReactForm({ fields }, { plugins, hooks });