import React, { useState, useCallback, useRef } from "react";
import { observer } from "mobx-react";
import licensesStore from "../../stores/licensesStore";
import LeavingModal from "../Modal/LeavingModal";
import SmallModal from "../Modal/SmallModal";
import styles from "./ProductItem.module.scss";
import { HandySvg } from "handy-svg";
import { toast } from "react-toastify";
import api from "../../api/http";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useEffect } from "react";
import ServerLog from "./serverLog";
import ItemHeader from "./itemHeader";
import ItemInfo from "./itemInfo";
import Share from "./share";

const formatCanceledAt = (d) => {
	const monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
	if (d !== null) {
		const date = new Date(d);
		const month = monthNames[date.getMonth()];
		return `${date.getDate()}/${month}/${date.getFullYear()}`;
	}
	return "indefinitely";
};

function ProductItem(license) {
	const timeout = useRef(null);
	const history = useHistory();

	const { id, current_period_end, product_name, status, shared_access } = license;
	const { getDownloadLink, cancelSubscription, resumeSubscription, setPMLicense } = licensesStore;
	const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
	const [logData, setLogData] = useState([]);
	const [formModalIsShown, setFormModalIsShown] = useState(false);
	const [formModalValue, setFormModalValue] = useState([]);
	const [isResuming, setIsResuming] = useState(false);
	const [openShareOption, setOpenShareOption] = useState(false);

	const handleChangePaymentMethod = () => {
		setPMLicense(license);
		setMenuOpen(false);
	};

	const genDownloadLink = useCallback(async () => {
		try {
			const resp = await getDownloadLink(id);
			const { link } = resp.data;
			if (navigator.userAgent.toLowerCase().includes("mac")) {
				window.location.href = link;
				return;
			}
			window.open(link, "_blank");
		} catch (e) {
			console.log(e);
		}
	}, [getDownloadLink, id]);

	const date = formatCanceledAt(current_period_end);

	const cancelSub = useCallback(async () => {
		try {
			await cancelSubscription(id, formModalValue);
		} catch (e) {
			console.log(e);
		}
	}, [cancelSubscription, id, formModalValue]);

	const isAnalyzer = () => {
		return product_name.toLowerCase().includes("analyzer");
	};

	const isProbe = () => {
		return product_name.toLowerCase().includes("probe");
	};

	const isActiveLicense = () => {
		return status === "active" || status === "trialing";
	};

	const handleOpenCancelSubscriptionModal = () => {
		setCancelSubscriptionModal(true);
	};

	const handleClosCancelSubscriptionModal = () => {
		setCancelSubscriptionModal(false);
	};

	const handleCancelSubscriptionInit = () => {
		setOpenShareOption(false);
		cancelSub();
	};

	const getLog = useCallback(async () => {
		let { data, status } = await api.getLogs(id);

		data !== null && status < 300
			? setLogData(data)
			: toast.error("Unable to retrieve data, please contact us at customersupport@vicuesoft.com");
	}, [id]);

	const handleToggleLog = async () => {
		setMenuOpen(false);
		logData.length > 0 ? setLogData([]) : getLog();
	};

	useEffect(() => {
		if (logData.length > 0) {
			timeout.current = setTimeout(() => getLog(), 5000);
		} else {
			clearTimeout(timeout.current);
		}
	}, [getLog, logData, timeout]);

	const goToManualActivation = () => {
		history.push("/activation/manual");
		setMenuOpen(false);
	};

	const handleOpenFormModal = () => {
		setFormModalIsShown(true);
		setMenuOpen(false);
	};

	const handleCloseFormModal = () => {
		setFormModalIsShown(false);
	};

	const acceptFormModal = (value) => {
		setFormModalValue(value);
		handleCloseFormModal();
		handleOpenCancelSubscriptionModal();
	};

	const isCancelsLicense = () => status === "canceled";

	const handleResumeSubscription = () => {
		resumeSub();
	};

	const resumeSub = useCallback(async () => {
		setIsResuming(true);
		try {
			await resumeSubscription(id);
			setIsResuming(false);
		} catch (e) {
			setIsResuming(false);
		}
	}, [resumeSubscription, id]);

	const handleShareLicense = () => {
		setMenuOpen(false);
		setOpenShareOption(!openShareOption);
	};

	const [menuOpen, setMenuOpen] = useState(false);

	const handleMenuToggle = () => {
		setMenuOpen(!menuOpen);
	};

	const clickHandler = (e) => {
		if (!e.target.matches(".options-menu") && !e.target.matches(".options-menu *")) {
			setMenuOpen(false);
		}
	};

	useEffect(() => {
		document.body.addEventListener("mousedown", clickHandler);
		return () => {
			document.body.removeEventListener("mousedown", clickHandler);
		};
	}, []);

	return (
		<>
			<div className={styles.item}>
				<ItemHeader
					license={license}
					handleOpenFormModal={handleOpenFormModal}
					handleChangePaymentMethod={handleChangePaymentMethod}
					handleMenuToggle={handleMenuToggle}
					menuOpen={menuOpen}
					goToManualActivation={goToManualActivation}
					handleToggleLog={handleToggleLog}
					logData={logData}
					handleShareLicense={handleShareLicense}
				/>
				<div className={styles["item-footer"]}>
					<ItemInfo license={license} date={date} />
					<div className={styles["item-footer-btns"]}>
						{(isActiveLicense() || (isCancelsLicense() && new Date(current_period_end) >= new Date())) && (
							<button className={styles.btn} onClick={genDownloadLink}>
								<HandySvg className={styles["btn-logo"]} width={20} height={20} src={"/static/download.svg"} />
								<span>Download</span>
							</button>
						)}
						{isCancelsLicense() && new Date(current_period_end) >= new Date() && (
							<button className={styles.btn} onClick={handleResumeSubscription}>
								<span>
									{!isResuming && <>Resume Subscription</>}
									{isResuming && <ClipLoader size={16} />}
								</span>
							</button>
						)}
					</div>
				</div>
				{openShareOption === true && <Share id={id} emails={shared_access.emails} />}
				{logData?.length > 0 && <ServerLog logData={logData} />}
				{formModalIsShown && <LeavingModal acceptModal={acceptFormModal} closeModal={handleCloseFormModal} />}
				{cancelSubscriptionModal && (
					<SmallModal
						closeModal={handleClosCancelSubscriptionModal}
						acceptCallback={handleCancelSubscriptionInit}
						title="Are you sure you want to cancel?"
						closeBtn="Go back"
						acceptBtn="Unsubscribe"
					>
						{isProbe() && (
							<React.Fragment>
								If you choose to cancel, your account will be downgraded to VQ Probe Free plan at the end of your
								current billing period on {date}.
								{/* If you choose to cancel, your subscription will be deleted right now. You can resume your subscription
								anytime in your accounts. */}
							</React.Fragment>
						)}
						{isAnalyzer() && (
							<React.Fragment>
								{/* If you choose to cancel, your subscription will be valid until the end of the paid period. */}
								If you choose to cancel, your subscription will be deleted right now. You can resume your subscription
								anytime in your accounts.
							</React.Fragment>
						)}
					</SmallModal>
				)}
			</div>
		</>
	);
}

export default observer(ProductItem);
