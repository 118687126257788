import React from 'react';
import { observer } from 'mobx-react';
import './index.css';

const SignInForm = ({ form }) => {
  return (
    <form className="signin">
      <div className="signin__row">
          <label htmlFor={form.$('email').id} className="signin__heading">{form.$('email').label}</label>
          <input className="signin__input" {...form.$('email').bind()} />
          {form.$('email').error && <div className="form__input-error">{form.$('email').error}</div>}
      </div>

      <div className="signin__row">
        <label htmlFor={form.$('password').id} className="signin__heading">{form.$('password').label}</label>
        <input className="signin__input" autoComplete="true" {...form.$('password').bind()} />
        {form.$('password').error && <div className="form__input-error">{form.$('password').error}</div>}
      </div>

      <button
        className="signin__button"
        type="button"
        onClick={form.onSubmit}>Sign In</button>
    </form>
  )
}

export default observer(SignInForm);
