import React from 'react';
import { observer } from 'mobx-react';
import './index.css';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const FrequentlyAskedQuestionsItem = ({ visibleText, notVisibleText }) => {
  const text = (
    <p style={{ paddingLeft: 24 }}>
      {notVisibleText}
    </p>
  );
  return (
    <>
    <Collapse bordered={false} className="FrequentlyAskedQuestionsItem">
      <Panel header={visibleText} key="1" className="FrequentlyAskedQuestionsItem-content-text">
        {text}
      </Panel>
    </Collapse>
    </>
  )
}

export default observer(FrequentlyAskedQuestionsItem);
