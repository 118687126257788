import React from 'react';
import { observer } from 'mobx-react';
import appStore from '../../stores/appStore';
import './index.css';

const Footer = () => {
  const { appInfo } = appStore;
  const { version } = appInfo;

  return (
    <>
      <footer className="Footer">
        <div className="Footer-payment-images-container">
          <img className="Footer-payment-images-container__image" src="/static/amex_payment.svg" alt="Payment Option"/>
          <img className="Footer-payment-images-container__image" src="/static/mastercard_payment.svg" alt="Payment Option"/>
          <img className="Footer-payment-images-container__image" src="/static/visa_payment.svg" alt="Payment Option"/>
          <img className="Footer-payment-images-container__image" src="/static/union_payment.svg" alt="Payment Option"/>
          <img className="Footer-payment-images-container__image" src="/static/discover_payment.svg" alt="Payment Option"/>
          <img className="Footer-payment-images-container__image" src="/static/jcb_payment.svg" alt="Payment Option"/>
        </div>
        <div className="Footer-copyright">
          Copyright © {new Date().getFullYear()}
        </div>
        <nav className="Footer-navigation">
          <li className="Footer-navigation__item"><a href="https://vicuesoft.com/privacy-policy">Privacy Policy</a></li>
          <li className="Footer-navigation__item"><a href="https://vicuesoft.com/terms-of-use">Term of Use</a></li>
        </nav>
      </footer>
      <div className="app-version">{version && `version: ${version}`}</div>
    </>
  )
}
export default observer(Footer)
