import React from 'react';
import { observer } from 'mobx-react';
import './index.css';

const TrialBanner = ({days}) => {
  return (
    <div className="TrialBanner">
      <div className="TrialBanner-text">
        <span className="TrialBanner-text--bold">Enjoy</span> your {days}-Days free trial!
      </div>
      <div className="TrialBanner-image" />
    </div>
  )
}

export default observer(TrialBanner);
