import React, { useEffect, useCallback } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { observer } from 'mobx-react';
import { eq, get } from 'lodash/fp';
import HttpStatus from 'http-status-codes';
import { useParams } from "react-router-dom";
import Plan from '../../components/PlanDetail';
import TrialBanner from '../../components/TrialBanner';
import plansStore from '../../stores/plansStore';
import './index.css';
import userStore from '../../stores/userStore';
import EnterprisePlan from '../../components/EnterprisePlan';
import FreePlan from '../../components/FreePlan';

const PlanContainer = () => {
  const { id } = useParams();
  const history = useHistory();
  const { getPlan, clearCoupon } = plansStore;
  const { user } = userStore;

  const fetchPlan = useCallback(async () => {
    try {
      await getPlan(id);
    } catch (e) {
      if (eq(HttpStatus.NOT_FOUND, get('response.status')(e))) {
        history.push("/");
      }
    }
  }, [getPlan, id, history]);

  useEffect(() => {
    fetchPlan();

    return function cleanup() {
      clearCoupon();
    }

  }, [fetchPlan, clearCoupon]);

  const { plan } = plansStore;

  return (
    <>
      {!user && !plan?.is_enterprise && <Redirect to="/" push />}
      <section className="PlanContainer">
        {plan && plan.is_free &&
          <FreePlan plan={plan} user={user} />
        }
        {plan && plan.is_enterprise &&
          <EnterprisePlan plan={plan} user={user} />
        }
        {plan && !plan.is_free && !plan.is_enterprise &&
          <>
            <TrialBanner days={plan.type === "VQPROBE" ? 14 : 3} />
            <Plan plan={plan} user={user} />
          </>
        }
      </section>
    </>
  )
}

export default observer(PlanContainer);
