import MobxReactForm from "mobx-react-form";
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import userStore from '../stores/userStore';

const plugins = {
  dvr: dvr(validatorjs)
}

const fields = [{
  name: 'email',
  label: 'Email',
  placeholder: '',
  rules: 'required|email|string',
  value: '',
  type: 'email'
}]

const hooks = {
  onSuccess(form) {
    const { resetPassword } = userStore;
    resetPassword(form.values())
  },
  onError(form) {},
}

export default new MobxReactForm({ fields }, { plugins, hooks });