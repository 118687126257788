import React from 'react';
import styles from "./error.module.scss";
import { Button } from 'antd';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className={styles.error_page}>
      <h1>404 Error</h1>
      <h2>page not found</h2>
      <Link to={'/'}>
        <Button >Go to main page</Button>
      </Link>
    </div>
  )
}