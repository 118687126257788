import React from 'react';
import { observer } from 'mobx-react';
import './index.css';

const HomePageBanner = () => {
  return (
    <div className="HomePageBanner">
      <div className="HomePageBanner-text-container">
        <div className="HomePageBanner-text-container__main-text">
          ViCueSoft Pricing
        </div>
        <div className="HomePageBanner-text-container__sub-text">
          We offer different plans according to your needs
        </div>
      </div>
    </div>
  )
}

export default observer(HomePageBanner);
