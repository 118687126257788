import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link, Redirect } from "react-router-dom";
import userStore from '../../stores/userStore';
import SignUpForm from '../../components/SignUpForm';
import signUpForm from '../../forms/signUpForm';

const SignUpContainers = () => {
  const { user, isSignUp, clearIsSignUp } = userStore;

  useEffect(() => {
    clearIsSignUp()
  }, [clearIsSignUp])

  return (
    <div className="private-pages__wrapper private-pages__wrapper--center">
      <section className="section-login">
        <h1 className="section-login__heading">Sign up</h1>
        <div className="section-login__content">
          {user && <Redirect to="/" push />}
          {!user && !isSignUp &&
            <>
              <SignUpForm form={signUpForm} />
              <div className='section-login__have'>Already have an account? Please <Link to="/sign-in">Sign in</Link></div>
            </>
          }
          {!user && isSignUp &&
            <div className="section-thank-you__content">
              <p className="paragraph paragraph--center">To confirm registration, check your email</p>
              <div className="section-thank-you__content--image">
                <img src="/static/email.svg" alt="Email" />
              </div>
            </div>
          }
        </div>
      </section>
    </div>
  )
};

export default observer(SignUpContainers)