import React from "react";
import licensesStore from "../../stores/licensesStore";
import InvoicesList from "../InvoicesList";
import NextInvoice from "../NextInvoice";
import styles from "./PaymentInfo.module.scss"
import { observer } from "mobx-react";
import userStore from "../../stores/userStore";

function PaymentInfo() {
  const { user } = userStore;
  const [activeProduct, setActiveProduct] = React.useState(null)
  const [products, setProducts] = React.useState([])
  const [productsPaymentData, setProductsPaymentData] = React.useState([]) // [] | 'it's free product'
  const { getLicenses, licenses, getPaymentsData } = licensesStore;


  const fetchLicenses = React.useCallback(async () => {
    await getLicenses();
  }, [getLicenses]);

  React.useEffect(() => {
    fetchLicenses();
  }, [fetchLicenses]);

  React.useEffect(() => {

    if (licenses.length) {

      const licensesExamination = (license) => license.status !== "invisible" && license.status !== "incomplete" && !license.is_free && !license.is_enterprise && license.owner === user.email

      const newProducts = licenses.filter(licensesExamination).map(
        ({ product_name, product_mode, id }) => {
          return {
            name: product_name,
            product_mode: product_mode,
            id: id
          }
        }
      )
      setProducts(newProducts)
      setActiveProduct(newProducts[0])
    }

  }, [licenses, setProducts, setActiveProduct, user]);


  React.useEffect(() => {
    const getActiveProductData = async () => {
      let response = await getPaymentsData(activeProduct.id);
      let responseStatus = response.status
      let responseData = response.data
      if (responseStatus === 200) {
        let filetResponseData = responseData.filter(item => item.status.length > 0);
        setProductsPaymentData(filetResponseData)
        return
      }
      setProductsPaymentData(responseData.message ? responseData.message : "something wrong")
    }

    if (activeProduct?.product_mode.toLowerCase().includes("free")) {
      setProductsPaymentData("it's free product")
      return
    }

    activeProduct && getActiveProductData()
  }, [activeProduct, setProductsPaymentData, getPaymentsData])


  return (
    <>
      <ul className={styles.list}>
        {products.map((item) => {
          return <li
            className={item.id === activeProduct.id ? styles["item-active"] : styles.item}
            onClick={() => setActiveProduct(item)}
            key={item.id}>
            {item.name} {item.product_mode}</li>
        })}
      </ul>
      {typeof productsPaymentData !== "string" && productsPaymentData.length > 0 &&
        <>
          <NextInvoice activeProductData={productsPaymentData} />
          <InvoicesList activeProductData={productsPaymentData} />
        </>
      }
      {typeof productsPaymentData === "string" && productsPaymentData.length > 0 && <>{productsPaymentData}</>}
    </>);
}

export default observer(PaymentInfo);