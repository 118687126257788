import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import { eq, get } from 'lodash/fp';
import HttpStatus from 'http-status-codes';
import plansStore from '../../stores/plansStore';
import userStore from '../../stores/userStore';
import SignInModal from '../SignInModal';
import './index.css';
// import { Checkbox } from 'antd';
import { validateEmail } from '../../util';

const EnterprisePlanForm = ({ form, plan }) => {
  const [isPaying, setPaying] = useState(false);
  const [isDownloaded, setDownloaded] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const { user, setToken, getUser } = userStore;
  const { payPlan } = plansStore;

  const handlePay = async (event) => {
    event.preventDefault();

    const values = form.values();

    if (!validateEmail(values.email)) {
      toast.error("Invalid e-mail address")
      return
    }

    setPaying(true);
    setDownloaded(false);
    setShowSignIn(false);

    try {
      const res = await payPlan(plan.id, {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        country: values.country,
        company: values.company,
        comment: values.comments
      });
      if (res.data.auth_token) {
        await setToken(res.data.auth_token);
      }
      await getUser();
      setPaying(false);
      if (res.data.download_link) {
        setDownloaded(true);
        window.location.href = res.data.download_link;
      } else {
        toast.success("Your VQ Probe Enterprise inquiry successfully processed. In the nearest time, our sail representative will contact you to discuss details.")
      }

      form.update({comments: ''});

    } catch (e) {
      setPaying(false);
      if (eq(HttpStatus.FORBIDDEN, get('response.status')(e))) {
        setShowSignIn(true);
      }
      if (eq(HttpStatus.BAD_REQUEST, get('response.status')(e))) {
        console.log(e);
        toast.error(get('response.data.message')(e));
      }
    }
  }

  const payIsDisabled = () => {
    const values = form.values();
    return values.email.trim() === "" ||
      values.first_name.trim() === "" ||
      values.last_name.trim() === "" ||
      values.country.trim() === "" ||
      values.company.trim() === "" ||
      isPaying || isDownloaded;
  }

  return (
    <>
      {!user && showSignIn && <SignInModal />}
      <div className="EnterprisePlanForm">
        <div className="EnterprisePlanForm__row">
          <label htmlFor={form.$('first_name').id} className="EnterprisePlanForm__heading">{form.$('first_name').label}<span>*</span></label>
          <input className="StripeElement EnterprisePlanForm__input" {...form.$('first_name').bind()} />
        </div>
        <div className="EnterprisePlanForm__row">
          <label htmlFor={form.$('last_name').id} className="EnterprisePlanForm__heading">{form.$('last_name').label}<span>*</span></label>
          <input className="StripeElement EnterprisePlanForm__input" {...form.$('last_name').bind()} />
        </div>
        <div className="EnterprisePlanForm__row">
          <label htmlFor={form.$('email').id} className="EnterprisePlanForm__heading">{form.$('email').label}<span>*</span></label>
          <input className="StripeElement EnterprisePlanForm__input" {...form.$('email').bind()} readOnly />
        </div>
        <div className="EnterprisePlanForm__row">
          <label htmlFor={form.$('company').id} className="EnterprisePlanForm__heading">{form.$('company').label}<span>*</span></label>
          <input className="StripeElement EnterprisePlanForm__input" {...form.$('company').bind()} />
        </div>
        <div className="EnterprisePlanForm__row">
          <label htmlFor={form.$('country').id} className="EnterprisePlanForm__heading">{form.$('country').label}<span>*</span></label>
          <input className="StripeElement EnterprisePlanForm__input" {...form.$('country').bind()} />
        </div>
        <div className="EnterprisePlanForm__row">
          <label htmlFor={form.$('comments').id} className="EnterprisePlanForm__heading">{form.$('comments').label}</label>
          <textarea className="StripeElement EnterprisePlanForm__input EnterprisePlanForm__input--comment" {...form.$('comments').bind()} />
        </div>
        {/* <div className="EnterprisePlanForm__checkbox">
          <Checkbox>I agree to receive newsletters and special offers</Checkbox>
        </div> */}

        <button
            className="EnterprisePlanForm__button"
            disabled={payIsDisabled()}
            onClick={handlePay}>
              {isPaying ? <ClipLoader size={18} color="#fff" /> : plan.type === "VQPROBE" ? "Submit" : "Download"}
          </button>

      </div>
    </>
  )
}

export default observer(EnterprisePlanForm);
