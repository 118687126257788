import React from 'react';
import styles from './Modal.module.scss';

function CheckIcon() {
  return (
    <svg
      className={styles.icon}
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.87777 1.09812L3.66199 7.86838C3.49995 8.04487 3.23827 8.04373 3.07754 7.86581L0.119873 4.59201C-0.0408602 4.41408 -0.0398268 4.12676 0.122215 3.95027L0.415646 3.63069C0.577688 3.4542 0.839365 3.45534 1.0001 3.63326L3.37565 6.26271L9.00461 0.131622C9.16665 -0.0448649 9.42833 -0.0437305 9.58906 0.134193L9.88011 0.456385C10.0409 0.634308 10.0398 0.921634 9.87777 1.09812Z"
        fill="#0466AD"
      />
    </svg>
  );
}

export default CheckIcon;
