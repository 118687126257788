import React from 'react';

const UsersContainer = () => {
  return (
    <>
      <div className="private-pages__wrapper">
        <div className="section-licenses">
          <h1 className="section-licenses__heading">Users</h1>
        </div>
      </div>
    </>
  )
}

export default UsersContainer