import axios from 'axios'
import HttpStatus from 'http-status-codes';
import { eq, get } from 'lodash/fp';

const AUTH_TOKEN = localStorage.getItem("token");

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
        Authorization: `Bearer ${AUTH_TOKEN}`,
    },
})

function setTokenHeader(token) {
    if (!token) {
        return;
    }

    localStorage.setItem("token", token);

    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    api.defaults.headers.Authorization = `Bearer ${token}`;
}

function getUser() {
    return api.get("/user").catch(
        r => {
            if (eq(HttpStatus.UNAUTHORIZED, get('response.status')(r))) {
                localStorage.removeItem("token");
            };
            return r
        }
    )
}

function signIn(data) {
    return api.post("/auth", data)
}

function signUp(data) {
    return api.post("/signup", data)
}

function resetPassword(data) {
    return api.post("/password/reset", data)
}

function changeCompany(data) {
    return api.put("/user", data)
}

function changePassword(data) {
    return api.put("/user", data)
}

function userDelete() {
    return api.post("/user/delete")
}

function checkingPossibilityUserDelete() {
    return api.get("/user/delete")
}

function signOut() {
    localStorage.removeItem("token");

    api.defaults.headers.common.Authorization = '';
    api.defaults.headers.Authorization = '';
}

function getPlans() {
    return api.get("/plans")
}

function getPlan(id) {
    return api.get(`/plans/${id}`)
}

function payPlan(id, data) {
    return api.post(`/plans/${id}/pay`, data);
}

function checkCoupon(id) {
    return api.get(`/coupons/${id}`);
}

function getLicenses() {
    return api.get("/licenses")
}

function cancelLicense(data) {
    return api.post("/licenses/cancel", data)
}

function getDownloadLink(id) {
    return api.post(`/licenses/${id}/download`);
}

function genLicenseKey(id) {
    return api.post(`/licenses/${id}/keys`);
}

function cancelSubscription(id, data) {
    return api.post(`/subscriptions/${id}/cancel`, data)
}

function resumeSubscription(id) {
    return api.post(`/subscriptions/${id}/resume`)
}

function changePaymentMethod(id, data) {
    return api.put(`/subscriptions/${id}/pm`, data)
}

function getAppInfo() {
    return api.get("/app/info")
}

function manualActivation(reqXML) {
    return api.post(`/activations/manual`, { "req_xml": reqXML })
}

function getLogs(license_id) {
    return api.get(`/servers/${license_id}/logs`).catch(error => error.response)
}

function getPaymentsData(license_id)  {
    return  api.get(`/subscriptions/${license_id}/invoices`).catch(error => error.response)
}

function shareLicenseAdd(data) {
    return api.post(`/subscriptions/share`, data).catch(error => error.response)
}
async function shareLicenseDel(data) { 
    console.log(data)
    let res = await api.delete(`/subscriptions/share`, {data: {...data}}).catch(error => error.response)
    console.log(res)

}

// eslint-disable-next-line
export default {
    setTokenHeader: setTokenHeader,
    getUser: getUser,
    signIn: signIn,
    signOut: signOut,
    getPlans: getPlans,
    getPlan: getPlan,
    resetPassword: resetPassword,
    changeCompany: changeCompany,
    changePassword: changePassword,
    checkingPossibilityUserDelete: checkingPossibilityUserDelete,
    userDelete: userDelete,
    getLicenses: getLicenses,
    payPlan: payPlan,
    cancelLicense: cancelLicense,
    getDownloadLink: getDownloadLink,
    genLicenseKey: genLicenseKey,
    checkCoupon: checkCoupon,
    cancelSubscription: cancelSubscription,
    resumeSubscription: resumeSubscription,
    changePaymentMethod: changePaymentMethod,
    getAppInfo: getAppInfo,
    manualActivation: manualActivation,
    getLogs: getLogs,
    getPaymentsData: getPaymentsData,
    signUp: signUp,
    shareLicenseAdd: shareLicenseAdd,
    shareLicenseDel: shareLicenseDel
}